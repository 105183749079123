import { useId } from 'react';
import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import {
	Table,
	TableRow,
	useAxios,
	DataCell,
	TableBody,
	TableHead,
	HeaderCell,
	EmptyTable,
	TextButton,
	TableLoading,
	ArrowLinkButton,
} from '@pangea-lis-apps/ui';
import {
	ASSAYS,
	ClinicData,
	formatDate,
	QueryState,
	PaginationState,
	getSpecimenTypes,
	getSampleStatuses,
	getLabelFromValue,
	getRoleFromPathname,
} from '@pangea-lis-apps/utils';

export const ViewSamplesProjectedFields = [
	'sample.status',
	'sample.accessioning_approval.reject.reason.value',
	'sample.accessioning_approval.flag.reason.value',
	'sample.report.aws_object_key',
	'_id',
	'sample.specimen_id',
	'sample.pangea_id',
	'sample.collection_kit_id',
	'patient.first_name',
	'patient.middle_name',
	'patient.last_name',
	'patient.date_of_birth',
	'patient.medical_record_number',
	'organization.name',
	'customer.first_name',
	'customer.last_name',
	'sample.assay',
	'sample.sample_specimen_type',
	'sample.sample_collection_date',
	'sample.sample_received_date',
	'sample.report.verified.metadata.date',
];

interface ViewSamplesTableProps {
	query: QueryState;
	pagination: PaginationState;
	data: ClinicData[] | undefined;
	tableType?:
		| 'report'
		| 'all-samples'
		| 'flagged-samples'
		| 'rejected-samples'
		| 'patients';
}

export default function ViewSamplesTable({
	data,
	tableType,
	pagination,
}: ViewSamplesTableProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const role = getRoleFromPathname();

	const navigate = useNavigate();

	const handleDownloadReport = async (dataId: string) => {
		if (!axios) return;

		toast.loading('Downloading...', toastOptions);

		try {
			const {
				data: { data },
			} = await (
				await axios
			).get(
				`/api/organization-shared/data/${dataId}/sample/report/?auth0_user_id=${user?.sub}`
			);

			toast.dismiss();

			window.open(data, '_blank');
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Table pagination={pagination}>
			<TableHead>
				{(tableType === 'all-samples' || tableType === 'report') && (
					<HeaderCell>Status</HeaderCell>
				)}
				{tableType === 'rejected-samples' && (
					<HeaderCell customWidth="w-96">Reject Reason</HeaderCell>
				)}
				{tableType === 'flagged-samples' && (
					<HeaderCell customWidth="w-96">On-Hold Reason</HeaderCell>
				)}
				{tableType === 'report' && <HeaderCell>Report</HeaderCell>}
				<HeaderCell>Specimen ID</HeaderCell>
				<HeaderCell>Collection kit ID</HeaderCell>
				<HeaderCell>Pangea ID</HeaderCell>
				<HeaderCell>Patient First Name</HeaderCell>
				<HeaderCell>Patient Middle Name</HeaderCell>
				<HeaderCell>Patient Last Name</HeaderCell>
				<HeaderCell>Patient Date of Birth</HeaderCell>
				<HeaderCell>Patient Medical Record Number (MRN)</HeaderCell>
				<HeaderCell>Test</HeaderCell>
				<HeaderCell>Specimen Type</HeaderCell>
				<HeaderCell customWidth="w-60">Collection Date</HeaderCell>
				<HeaderCell>Received Date</HeaderCell>
				{tableType === 'report' && <HeaderCell>Report Date</HeaderCell>}
				<HeaderCell>Provider</HeaderCell>
			</TableHead>
			<TableBody>
				{data ? (
					data.length ? (
						data.map((datum, index) => (
							<TableRow key={index} index={index}>
								{(tableType === 'all-samples' ||
									tableType === 'report') && (
									<DataCell>
										{getLabelFromValue(
											datum.sample.status,
											getSampleStatuses()
										)}
									</DataCell>
								)}
								{tableType === 'rejected-samples' && (
									<DataCell>
										{
											datum.sample.accessioning_approval
												.reject.reason.value
										}
									</DataCell>
								)}
								{tableType === 'flagged-samples' && (
									<DataCell>
										{
											datum.sample.accessioning_approval
												.flag.reason.value
										}
									</DataCell>
								)}
								{tableType === 'report' && (
									<DataCell>
										{datum.sample.report.aws_object_key && (
											<TextButton
												text="View"
												color="blue"
												type="button"
												onClick={() =>
													handleDownloadReport(
														datum._id.$oid
													)
												}
											/>
										)}
									</DataCell>
								)}
								<DataCell>{datum.sample.specimen_id}</DataCell>
								<DataCell>
									{datum.sample.collection_kit_id}
								</DataCell>
								<DataCell>
									<ArrowLinkButton
										text={datum.sample.pangea_id || 'N/A'}
										onClick={() =>
											navigate(
												`/${role}/specimens/${datum._id.$oid}`
											)
										}
									/>
								</DataCell>
								<DataCell>
									{datum.patient
										? datum.patient.first_name
										: null}
								</DataCell>
								<DataCell>
									{datum.patient
										? datum.patient.middle_name
										: null}
								</DataCell>
								<DataCell>
									{datum.patient
										? datum.patient.last_name
										: null}
								</DataCell>
								<DataCell>
									{datum.patient
										? formatDate(
												datum.patient.date_of_birth
										  )
										: null}
								</DataCell>
								<DataCell>
									{datum.patient
										? datum.patient.medical_record_number
										: null}
								</DataCell>
								<DataCell>
									{getLabelFromValue(
										datum.sample.assay,
										ASSAYS['assays']
									)}
								</DataCell>
								<DataCell>
									{getLabelFromValue(
										datum.sample.sample_specimen_type,
										getSpecimenTypes()
									)}
								</DataCell>
								<DataCell>
									{formatDate(
										datum.sample.sample_collection_date
									)}
								</DataCell>
								<DataCell>
									{formatDate(
										datum.sample.sample_received_date
									)}
								</DataCell>
								{tableType === 'report' && (
									<DataCell>
										{datum.sample.report.verified.metadata
											.date &&
											formatDate(
												datum.sample.report.verified
													.metadata.date?.$date
											)}
									</DataCell>
								)}
								<DataCell>
									{datum.customer.first_name.concat(
										' ',
										datum.customer.last_name
									)}
								</DataCell>
							</TableRow>
						))
					) : (
						<EmptyTable
							heading="No Results Found"
							Icon="DocumentChartBarIcon"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
}
