import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useId, Fragment, useState, useEffect } from 'react';

import {
	Button,
	useAxios,
	Container,
	TextButton,
	LoadingBox,
	FormFooter,
	EditDataModal,
	ContentWrapper,
	ContentSection,
	DescriptionItem,
	DescriptionList,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	FORMS,
	Option,
	Attribute,
	capitalize,
	getLabelFromValue,
	getRoleFromPathname,
	BaseHydratedCustomer,
	initialAttributeValue,
} from '@pangea-lis-apps/utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProfileProps {}

export function Profile(props: ProfileProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const role = getRoleFromPathname();
	const { user: auth0_user, logout } = useAuth0();

	const [refresh, setRefresh] = useState(false);
	const [user, setUser] = useState<BaseHydratedCustomer | undefined>(
		undefined
	);
	const [modalVisible, setModalVisible] = useState(false);
	const [attribute, setAttribute] = useState<Attribute>(
		initialAttributeValue
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !auth0_user) return;

			try {
				const {
					data: { data },
				} = await (await axios).get(`/api/${role}/user-info`);

				if (!unmounted) {
					setUser(JSON.parse(data));
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, refresh, role, auth0_user]);

	const handleClick = (
		property: string,
		value: string,
		label: string,
		type: 'text' | 'select' | 'date' | 'multiple_select' | 'file' | 'email',
		selectOptions?: Option[],
		selectLabel?: string
	) => {
		setAttribute({
			property,
			value,
			type,
			label,
			selectOptions,
			selectLabel,
		});
		setModalVisible(true);
	};

	const handleChangePassword = async () => {
		if (!axios) return;

		toast.loading('Loading...', toastOptions);

		try {
			await (await axios).patch(`/api/${role}/change-password`, null);

			toast.success(
				'Check your email to reset your password.',
				toastOptions
			);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Fragment>
			<EditDataModal
				data={user}
				attribute={attribute}
				setRefresh={setRefresh}
				heading="Edit info"
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
				endpoint={`/api/${role}/user-info`}
			/>
			<Container>
				<ContentWrapper>
					{!auth0_user || !user ? (
						<LoadingBox />
					) : (
						<Fragment>
							<ContentSection>
								<div className="pt-8">
									<h1 className="text-2xl font-bold text-gray-900">
										{capitalize(
											auth0_user[
												'custom_claims/first_name'
											]
										)}{' '}
										{capitalize(
											auth0_user[
												'custom_claims/last_name'
											]
										)}
									</h1>
								</div>
							</ContentSection>

							<ContentSectionDivider />

							<ContentSection
								heading="Info"
								description={
									user.entity === 'clinic' &&
									'If the National Provider Identifier (NPI) is incorrect, please contact clientservices@pangealab.com.'
								}
							>
								<DescriptionList>
									<DescriptionItem
										term="Organization"
										details={user.organization.name}
									/>
									{user.entity === 'clinic' &&
									user.national_provider_identifier ? (
										<Fragment>
											<DescriptionItem
												term="National Provider Identifer (NPI)"
												details={
													user.national_provider_identifier
												}
											/>
											<div className="sm:col-span-1"></div>
										</Fragment>
									) : (
										<div className="sm:col-span-2"></div>
									)}
									<DescriptionItem
										term="First Name"
										details={user.first_name}
									/>
									<DescriptionItem
										term="Last Name"
										details={user.last_name}
									/>
									<DescriptionItem term="Password">
										<TextButton
											color="blue"
											type="button"
											text="Change"
											onClick={handleChangePassword}
										/>
									</DescriptionItem>
								</DescriptionList>
							</ContentSection>

							<ContentSectionDivider />

							<ContentSection
								heading="Contact"
								description="After updating your email address, you will be logged out of the session and receive a new email to verify your updated email address."
							>
								<DescriptionList>
									<DescriptionItem
										withFunction
										Icon="PencilIcon"
										term="Email Address"
										details={user.email_address}
										handleClick={() =>
											handleClick(
												'email_address',
												user.email_address,
												'Email Address',
												'email'
											)
										}
									/>
									<DescriptionItem
										withFunction
										Icon="PencilIcon"
										term="Phone Number"
										details={user.phone_number}
										handleClick={() =>
											handleClick(
												'phone_number',
												user.phone_number,
												'Phone Number',
												'text'
											)
										}
									/>
								</DescriptionList>
							</ContentSection>

							<ContentSectionDivider />

							<ContentSection heading="Address">
								<DescriptionList>
									<DescriptionItem
										withFunction
										Icon="PencilIcon"
										term="Address"
										details={user.address}
										handleClick={() =>
											handleClick(
												'address',
												user.address,
												'Address Info - Address',
												'text'
											)
										}
									/>
									<div className="sm:col-span-2"></div>
									<DescriptionItem
										withFunction
										Icon="PencilIcon"
										term="City"
										details={user.city}
										handleClick={() =>
											handleClick(
												'city',
												user.city,
												'Address Info - City',
												'text'
											)
										}
									/>
									<DescriptionItem
										withFunction
										Icon="PencilIcon"
										term="State"
										details={getLabelFromValue(
											user.state,
											FORMS.states
										)}
										handleClick={() =>
											handleClick(
												'state',
												getLabelFromValue(
													user.state,
													FORMS.states
												),
												'Address Info - State',
												'select',
												FORMS.states
											)
										}
									/>
									<DescriptionItem
										withFunction
										Icon="PencilIcon"
										term="ZIP Code"
										details={user.zip_code}
										handleClick={() =>
											handleClick(
												'zip_code',
												user.zip_code,
												'Address Info - ZIP Code',
												'text'
											)
										}
									/>
									<DescriptionItem
										withFunction
										Icon="PencilIcon"
										term="Country"
										details={getLabelFromValue(
											user.country,
											FORMS.countries
										)}
										handleClick={() =>
											handleClick(
												'country',
												getLabelFromValue(
													user.country,
													FORMS.countries
												),
												'Address Info - Country',
												'select',
												FORMS.countries
											)
										}
									/>
								</DescriptionList>
							</ContentSection>
						</Fragment>
					)}

					<FormFooter>
						<Button
							type="button"
							text="Log Out"
							tier="tertiary"
							Icon="ArrowRightOnRectangleIcon"
							onClick={() =>
								logout({
									returnTo: window.location.origin,
								})
							}
						/>
					</FormFooter>
				</ContentWrapper>
			</Container>
		</Fragment>
	);
}

export default Profile;
